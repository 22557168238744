<template>
    <div>
        <div class="moodboard-detail page-wrapper" v-if="dataLoaded">
            <div class="pb-6">
                <div
                    class="d-flex flex-column justify-content-center position-relative"
                >
                    <div
                        class="container-longer container-fluid text-white text-center"
                    >
                        <div class="row mt-6 mb-4 mt-xl-7">
                            <h1 class="col-12">
                                {{ object.title }}
                            </h1>
                            <div
                                class="col-12 text-bigger"
                                v-if="object.subtitle"
                            >
                                {{ object.subtitle }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modularne sekcije -->
                <template v-if="object.modules && object.modules.length > 0">
                    <Modules :modules="object.modules" :color="object.color" />
                </template>
                <!-- kraj modularnih sekcija -->
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import seoMixin from "@/mixins/seoMixin";
import Modules from "../components/Modules.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "moodboard-detail",
    mixins: [seoMixin],
    data() {
        return {
            videoPaused: true,
            dataLoaded: false
        };
    },
    components: {
        Modules,
        ArticleHeader,
        Footer
    },
    created() {
        const slug = this.$route.params.slug;
        const pageApi = `wp-json/wp/v2/posts?slug=${slug}`;
        const data = axios
            .get(pageApi)
            .then(res => {
                this.object = res.data[0];
                // from seoMixin
                this.setSeo(
                    this.object.seo_title,
                    this.object.seo_subtitle,
                    "https://www.wearealive.com/WAA_default.jpeg",
                    "https://www.wearealive.com" + this.$route.fullPath
                );
                this.dataLoaded = true;
                document.dispatchEvent(new Event("custom-render-trigger"));

                setTimeout(() => {
                    $("body").css("visibility", "unset");
                    this.$store.dispatch("setLoading", false);
                }, loadingDelay);
            })
            .catch(e => {
                this.$router.push("/404");
            });
    }
};
</script>
